/*
 * @Author: your name
 * @Date: 2021-05-24 19:52:06
 * @LastEditTime: 2021-05-24 19:52:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /fe-datahub/src/pages/Layout/components/Logo.tsx
 */
import React from 'react';
import smallLogo from 'static/images/oms-logo-black-small.svg';
import logo from 'static/images/oms-logo-black.svg';

interface IProps {
  collapsed: boolean;
}

const Logo = (props: IProps) => {
  const { collapsed } = props;
  return (
    <div className={`custom-logo ${collapsed ? 'collapsed' : ''}`}>
      <img className="logo" src={collapsed ? smallLogo : logo} alt="logo" />
    </div>
  );
};
export default Logo;
