/*
 * @Author: 董方旭
 * @Date: 2021-02-23 14:31:53
 * @LastEditors: 董方旭
 * @LastEditTime: 2021-10-25 19:37:04
 * @Description: 菜单
 */
import { Menu } from 'antd';
import {
  addFlag2Route,
  concatPath,
  filterAuthedRoute,
  hasChildren,
  type RouteConfig as CosmosRouteConfig,
} from 'components/CoreRouter';
import IconFont from 'components/IconFont';
import routeConfig from 'configs/router.conf';
import longan from 'longan-sdk';
import React, { memo, useEffect, useState, type ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/globalSlice';
import { selectActiveCacheKey, selectCachingKeys, selectLoginUserFunctionAuth } from 'store/selectors';
import styled from 'styled-components';
import globalMessages, { menuMessages } from 'utils/messages';

import CustomLink from './CustomBtnLink';
import TooltipSpan from './TooltipSpan';

export type TExtend = {
  /** 菜单的名称 */
  name?: string;
  /** 菜单的icon */
  icon?: string | ReactNode;
  /** 是否在菜单中隐藏 */
  hidden?: boolean;
  /** TODO 菜单的icon 暂时先用图片代替，以后换成iconFont */
  image?: string;
};

export type TRouteConfig = CosmosRouteConfig<TExtend>;

const MenuWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  /* Firefox */
  scrollbar-width: none;
  /* IE10+ */
  -ms-overflow-style: none;
  /* Chrome Safari */
  &::-webkit-scrollbar {
    display: none;
  }

  /* menu基础padding */
  .ant-menu.ant-menu-root {
    padding: 0 4px 8px 4px;
  }
  /* 展开状态下menuitem的padding */
  .ant-menu.ant-menu-inline:not(.ant-menu-inline-collapsed) .ant-menu-item:not(.ant-menu-item-only-child) {
    padding: 0 8px !important;
  }
  /* 二级菜单item的margin-bottom置为0 */
  .ant-menu-item.ant-menu-item-only-child:last-child {
    margin-bottom: 0;
  }
  /* 有二级菜单的父级菜单padding设置为8px */
  .ant-menu-submenu.ant-menu-submenu-inline {
    .ant-menu-submenu-title {
      padding: 0 8px !important;
    }
  }
  /* 二级菜单文字左边距去掉-8px，置为0 */
  .ant-menu-inline.ant-menu-root .ant-menu-item > *:first-child,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > *:first-child {
    margin-left: 0;
  }
  /* 菜单文字高度固定和父元素保持一致 */
  .ant-menu-title-content {
    height: inherit;
  }
`;

const validMenuItem = ({ path, hidden }: TRouteConfig) => path && !path.match(/:/) && !hidden;

const isStaticPath = ({ path, hidden }: TRouteConfig) => !path.match(/:/) && !hidden;

const formatMenuItem = (intl: IntlShape, configs: TRouteConfig[], parentPath = '') =>
  configs.filter(validMenuItem).map(({ path, children, exist, icon }) => {
    const menuIcon = typeof icon === 'string' ? <IconFont type={icon} /> : icon;
    const text = intl.formatMessage(menuMessages[path]);
    const allPath = concatPath(parentPath, path);
    if (hasChildren(children)) {
      const staticChildren = children.filter(isStaticPath);
      if (staticChildren.length) {
        return (
          <Menu.SubMenu
            key={path}
            title={exist ? <CustomLink to={allPath}>{text}</CustomLink> : <TooltipSpan>{text}</TooltipSpan>}
            icon={menuIcon}
          >
            {formatMenuItem(intl, staticChildren, allPath)}
          </Menu.SubMenu>
        );
      }
    }

    return (
      <Menu.Item key={allPath} title={text} icon={menuIcon}>
        <CustomLink to={allPath}>{text}</CustomLink>
      </Menu.Item>
    );
  });

/**
 * 左侧菜单组件
 */
const CustomMenu = memo(() => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const authList = useSelector(selectLoginUserFunctionAuth);
  const cachingKeys = useSelector(selectCachingKeys);
  const activeCacheKey = useSelector(selectActiveCacheKey);

  // eslint-disable-next-line
  const checkAuth = (item: any) => {
    // 如果两种权限都没有，则认为不要权限控制，展示菜单路由
    if (!(item.authOnlyNoSecret || item.authOnlySecret)) {
      return true;
    }
    // 否则，判断是否拥有对应权限
    return (
      (authList || []).indexOf(item.authOnlyNoSecret) !== -1 || (authList || []).indexOf(item.authOnlySecret) !== -1
    );
  };
  const authFlagRoutes = addFlag2Route(routeConfig, checkAuth);
  const routes = filterAuthedRoute(authFlagRoutes);
  const rootSubmenuKeys = routes.filter(item => !!item.children && !item.exist).map(filterItem => filterItem.path);

  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    /** 切换路由修改title ---start--- */
    const paths = activeCacheKey.split('/');
    const titleKeyIndex = paths.findIndex(path => path.includes(':'));
    const titleKey = titleKeyIndex === -1 ? paths[paths.length - 1] : paths[titleKeyIndex - 1];
    window.document.title = `${intl.formatMessage(globalMessages.systemName)}-${intl.formatMessage(
      menuMessages[(titleKey as keyof typeof menuMessages) || 'homePage'],
    )}`;
    /** 切换路由修改title ---end--- */
    const endIndex = activeCacheKey.indexOf('/:');
    setOpenKeys(activeCacheKey.split('/').slice(1));
    if (endIndex === -1) {
      // 不是详情页面
      setSelectedKeys([activeCacheKey]);
    } else {
      // 详情页面
      const key = activeCacheKey.slice(0, endIndex);
      const selectedMenuIndex = key.lastIndexOf('/');
      const selectedMenu = key.slice(0, selectedMenuIndex);
      setSelectedKeys([selectedMenu]);
    }
    // 触发resize事件
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }, [activeCacheKey]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // eslint-disable-next-line
  const onClick = (menuItem: any) => {
    const { key } = menuItem;
    const endIndex = key.indexOf('/:');
    setOpenKeys(key.split('/').slice(1));
    setSelectedKeys([endIndex === -1 ? key : key.slice(0, endIndex)]);
    const mesKey = key.split('/').pop();
    const text = intl.formatMessage(menuMessages[mesKey]);
    longan.dispatch({
      event_desc: text,
      event_name: `page-${text}`,
      event_type: 'selfDefine',
    });
    if (!cachingKeys.includes(key)) {
      dispatch(actions.updateCachingKeys([...cachingKeys, key]));
    }
    if (activeCacheKey !== key) {
      dispatch(actions.updateActiveCacheKey(key));
    }
  };

  return (
    <MenuWrapper>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onClick={onClick}
      >
        {formatMenuItem(intl, routes)}
      </Menu>
    </MenuWrapper>
  );
});

export default CustomMenu;
