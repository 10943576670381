import { Tooltip } from 'antd';
import React, { useLayoutEffect, useRef, useState } from 'react';

const TooltipSpan = ({
  children,
  innerSpanStyle,
  offsetRX = 12,
}: {
  children: React.ReactNode;
  /**
   * 内部span的样式
   * @description span本身设置了默认的样式，传入之后会进行merge
   */
  innerSpanStyle?: React.CSSProperties;
  /**
   * tooltip向右的偏移量
   * @description 默认为12px
   */
  offsetRX?: number;
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const spanRef = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (!spanRef.current) return;
    const element = spanRef.current;
    const isOverflow = element.scrollWidth > element.offsetWidth;
    setIsOverflowing(isOverflow);
  }, [children]);

  return (
    <Tooltip
      title={children}
      trigger={isOverflowing ? 'hover' : ''}
      placement="right"
      align={{ offset: [offsetRX, 0] }}
    >
      <span
        ref={spanRef}
        style={{
          display: 'inline-block',
          width: '100%',
          paddingRight: '14px',
          overflow: 'hidden',
          textAlign: 'start',
          textOverflow: 'ellipsis',
          ...innerSpanStyle,
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default TooltipSpan;
