/*
 * @Author: 董方旭
 * @Date: 2021-03-19 14:50:34
 * @LastEditors: 董方旭
 * @LastEditTime: 2021-03-19 16:47:30
 * @Description: menu utils helper
 */
import { IMenuDataItem } from 'components/Router/types';
// import { GLOBAL_MSG_SCOPE } from 'utils/constants';
import { IntlShape } from 'react-intl';
import { menuMessages } from 'utils/messages';
// import { getFormattedMessages } from '../../i18n';

// const MENU_I18N_PREFIX = `${GLOBAL_MSG_SCOPE}.menu`;

// export function getText(lang: string, key: string, messages = menuMessages) {
//   let msg = (messages as any)[key] || key;
//   if (messages) {
//     msg = (messages as any)[key] || msg;
//   }

//   return getFormattedMessages(lang, `${MENU_I18N_PREFIX}.${key}`, msg);
// }

/**
 * 根据语言将menu.conf进行修改，非纯函数，会改变传入参数的值，需要注意；
 * @param {*} menuData
 * @param {*} lang
 */
export function getMenuLangData(
  intl: IntlShape,
  lang: string,
  menuData: IMenuDataItem[],
  messages: any,
  authList: any,
): IMenuDataItem[] {
  const result = [] as any;
  menuData.forEach(element => {
    const text = intl.formatMessage(menuMessages[element.path]);
    // const text = getText(lang, element.path, messages);
    // element.text = text;
    const { children, ...rest } = element;
    const item = {
      ...rest,
      text,
    } as IMenuDataItem;
    if (children) {
      item.children = getMenuLangData(intl, lang, children, messages, authList);
    }
    // 没有权限码 || 有相应的权限
    if (
      (item.authOnlySecret && authList.includes(item.authOnlySecret)) ||
      (item.authOnlyNoSecret && authList.includes(item.authOnlyNoSecret)) ||
      (!item.authOnlySecret && !item.authOnlyNoSecret)
    ) {
      result.push(item);
    }
  });
  return result;
}

export default {};
