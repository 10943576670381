/*
 * @Author: 李淳
 * @Date: 2020-06-22 11:16:00
 * @LastEditors: liqingqing
 * @LastEditTime: 2021-10-18 14:49:43
 * @Description: file content
 */
import { getRequest } from 'utils/request';
import { ICompany, ICompanyGroupList, IVendor, IWarehouse } from './types';

// service，要求以Service为结尾进行命名
export const getLoginUserInfoService = () => getRequest('/base/basic/getuserprivilege');

// 获取下发配置列表（所有map中生效的配置）
export const getIssueConfService = () => getRequest('/omsdata/basiccode/issueconf', { is_all: 1, is_effect: 1 });

// 获取所有仓库列表
export const getAllWarehouseListService = () =>
  getRequest<{ is_user: number }, IListResponse<IWarehouse>>('/omsdata/warehouse/warehousesug', { is_user: 2 });

// 获取用户数据权限下的仓库列表
export const getWarehouseListService = () =>
  getRequest<{ is_user: number }, IListResponse<IWarehouse>>('/omsdata/warehouse/warehousesug', { is_user: 1 });

// 获取所有供应商列表
export const getAllVendorListService = () =>
  getRequest<{ is_user: number }, IVendor>('/omsdata/vendor/vendorsug', {
    is_user: 2,
  });

// 获取用户数据权限下的供应商列表
export const getVendorListService = () =>
  getRequest<{ is_user: number }, IVendor>('/omsdata/vendor/vendorsug', {
    is_user: 1,
  });

// 获取所有货主列表
export const getAllCompanyListService = () =>
  getRequest<{ is_user: number }, IListResponse<ICompany>>('/omsdata/company/companysug', { is_user: 2 });

// 获取用户数据权限下的货主列表
export const getCompanyListService = () =>
  getRequest<{ is_user: number }, IListResponse<ICompany>>('/omsdata/company/companysug', { is_user: 1 });

// 获取用户数据权限下的货主组列表
export const getCompanyGroupListService = () =>
  getRequest<void, IListResponse<ICompanyGroupList>>('/omsdata/companygroup/authlist');

// 获取最新的公告
export const getNewNoticeService = () => getRequest<void, ICompanyGroupList>('/omsdata/notice/getnotice');

export default {
  getLoginUserInfoService,
  getIssueConfService,
  getAllWarehouseListService,
  getWarehouseListService,
  getAllCompanyListService,
  getCompanyListService,
  getCompanyGroupListService,
  getNewNoticeService,
  getAllVendorListService,
  getVendorListService,
};
