/*
 * @Author: 李淳
 * @Date: 2020-07-23 11:39:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-24 21:38:16
 * @Description: file content
 */
import { Layout } from 'antd';
import styled from 'styled-components';

export const LayoutWrapper = styled(Layout)`
  height: 100vh;
  overflow: hidden;
  /* logo样式 */
  .custom-logo {
    width: 100%;
    height: 56px;

    .logo {
      width: 200px;
      height: 56px;
    }

    &.collapsed {
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        width: 32px;
        height: 32px;
      }
    }
  }
  /* 侧边栏样式 */
  .custom-sider {
    .ant-layout-sider-children {
      border-right: 1px solid #eaeaea;
    }
    .ant-layout-sider-trigger {
      border-right: 1px solid #eaeaea;
      text-align: unset;
      line-height: 24px;
      padding: 12px;

      .anticon {
        margin: 4px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .scroll-content {
    height: 100px;
    flex-grow: 1;
    flex-shrink: 0;
    overflow-y: scroll;
  }
  .main-content-wrap {
    height: 100%;
    padding: 20px;
  }
`;

export const HeaderWrapper = styled(Layout.Header)`
  height: 56px;
  line-height: 56px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  box-shadow: 4px 5px 12px 0px rgba(0, 0, 0, 0.04);
  z-index: 999;
  background-color: #ffffff;
  padding: 0 20px;

  .menu-tabs {
    flex: 1;
    overflow: hidden;
  }

  .global-action {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    img {
      width: 20px;
      height: 20px;
      margin-left: 20px;
      cursor: pointer;

      /* &:first-child {
        margin-left: 0px;
      } */
    }

    .ant-radio-group {
      display: block;
    }
  }
`;

export const MultipleTabsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .tabs {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: auto;
    /* height: 50px; */

    .ant-tabs {
      overflow: hidden;
      .ant-tabs-nav {
        margin-bottom: 0;
      }
    }
    .ant-tabs-tab-btn {
      padding-top: 1px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      /* color: #424656; */
      font-weight: 500;
      text-shadow: none;
    }
  }
`;

export default {
  LayoutWrapper,
  HeaderWrapper,
  MultipleTabsWrapper,
};
