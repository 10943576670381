import { useDispatch } from 'react-redux';
import { getCachingKeys } from 'react-router-cache-route';
import { useHistory } from 'react-router-dom';
import { actions } from 'store/globalSlice';

interface IPageJump {
  pathname: string;
  state: any;
}

const usePageJump = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (params: string | IPageJump) => {
    const pathname = typeof params === 'string' ? params : params.pathname;
    const state = typeof params === 'string' ? undefined : params.state;
    history.push({
      pathname,
      state,
    });
    const cachingKeys = getCachingKeys();
    if (!cachingKeys.includes(pathname)) {
      dispatch(actions.updateCachingKeys([...cachingKeys, pathname]));
    }
    dispatch(actions.updateActiveCacheKey(pathname));
  };
};

export default usePageJump;
