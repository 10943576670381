/**
 * pass相关的配置
 */
import { getCookie } from 'utils/utils';

const PLATFORM_COOKIE = 'tblh-platform';

const conf = {
  enable: true,
  uri: {
    login: '/pass/login',
    logout: '/pass/logout',
    editpwd: '/pass/editpwd',
    bindphone: '/pass/bindphone',
  } as const,
  platformMap: {
    online: 'TSC-TBLH-SCOMS|MTExMQ',
    uat: 'TSC-TBLH-SCOMS|MTExMw',
    test: 'TSC-TBLH-SCOMS|MTExNg',
  } as const,
};

/**
 * 跳转到pass具体页面
 * @param type 页面类型
 */
export const gotoPass = (type: keyof typeof conf.uri) => {
  if (conf.enable && conf.uri[type]) {
    const platform =
      getCookie(PLATFORM_COOKIE) || conf.platformMap[process.env.PROJECT_ENV as keyof typeof conf.platformMap];
    window.location.href = `${conf.uri[type]}?platform=${platform}`;
  }
};

export default {};
