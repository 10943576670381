import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import globalMessages from 'utils/messages';

const NotFoundPageContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .not_found_bg {
    font-size: 60px;
    color: #333;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .not_found_tip {
    font-size: 12px;
    color: #999;
    margin-bottom: 5px;
  }
`;

export default function NotFoundPage() {
  const intl = useIntl();
  return (
    <NotFoundPageContainer>
      <div className="not_found_bg">404</div>
      <div className="not_found_tip">{intl.formatMessage(globalMessages.pageNotFound)}</div>
      <div className="not_found_tip">{intl.formatMessage(globalMessages.noAuth)}</div>
    </NotFoundPageContainer>
  );
}
