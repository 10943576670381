/*
 * @Author: 董方旭
 * @Date: 2021-03-17 14:34:01
 * @LastEditors: 董方旭
 * @LastEditTime: 2021-06-29 11:52:29
 * @Description: 验证构建版本是否和当前版本一致。如果有新版本发行，提示用户刷新
 */
import { Modal } from 'antd';
import { ModalFunc } from 'antd/lib/modal/confirm';
import { getGlobalIntl } from 'i18n/i18n';
import get from 'lodash/get';
import globalMessages from './messages';
import { getRequest } from './request';

const getBuildVersion = (params: { timestamp: string }) => getRequest('/static/pollJson.json', params);
const golbalIntl = getGlobalIntl();

const LOOP_TIME = 60000;

interface IVersionInfo {
  commit: string;
  commitUserName: string;
  commitUserMail: string;
  commitDate: string;
  buildUserName: string;
  buildUserMail: string;
  buildDate: string;
}

class BuildVersion {
  static instance: BuildVersion;

  info: IVersionInfo;

  modal: ModalFunc | false;

  constructor(versionInfo: IVersionInfo) {
    this.info = versionInfo;
  }

  static getInstance(versionInfo: IVersionInfo) {
    if (!this.instance) {
      this.instance = new BuildVersion(versionInfo);
      console.log(versionInfo);
    }
    return this.instance;
  }

  init = () => {
    this.checkVersion();
  };

  checkVersion = () => {
    setTimeout(() => {
      const timestamp = new Date().getTime().toString();

      getBuildVersion({ timestamp })
        .then((data: any) => {
          // const data = JSON.parse(d);
          const commit = get(data, ['data', 'commit'], '');
          const buildDate = get(data, ['data', 'buildDate'], golbalIntl.formatMessage(globalMessages.earlier));
          const isPoll = get(data, ['isPoll']);
          if (isPoll && commit !== this.info.commit) {
            const startTime = get(data, ['startTime']);
            const endTime = get(data, ['endTime']);
            if (new Date().getHours() >= startTime && new Date().getHours() <= endTime) {
              this.alert(buildDate);
            } else {
              this.checkVersion();
            }
          } else {
            this.checkVersion();
          }
        })
        .catch(err => {
          console.log('err: ', err);
          this.checkVersion();
        });
    }, LOOP_TIME);
  };

  alert = (buildDate: string) => {
    if (!this.modal) {
      this.modal = Modal.confirm;
      this.modal({
        width: '500px',
        zIndex: 100000,
        title: golbalIntl.formatMessage(globalMessages.newVersion),
        content: golbalIntl.formatMessage(globalMessages.newVersionInfo, { buildDate }),
        okText: golbalIntl.formatMessage(globalMessages.refresh),
        cancelText: golbalIntl.formatMessage(globalMessages.later),
        onCancel: () => {
          this.checkVersion();
          this.modal = false;
        },
        onOk: () => {
          // 原来这里写的是window.location.reload(true)，但会出现ts报错，原因是reload()方法其实并不支持参数
          // window.location.reload(true)这个方法在大多数浏览器中并不能达到跳过缓存强制刷新页面的效果
          // 具体原因可以参考这里：https://developer.mozilla.org/zh-CN/docs/Web/API/Location/reload
          // 为了解决ts报错，这里去掉了true这个参数
          window.location.reload();
        },
      });
    }
  };
}

export default BuildVersion;
