/*
 * 生产环境权限 - 主要有PAGE和FUNC两种权限, 在authmap中取到undefined，则默认显示权限
 * PAGE一般用于页面，比如路由配置中会用到
 * FUNC一般用于功能权限，比如页面上的按钮，增删改查等
 */

const authMap = {
  AUTH_PAGE_sample: 999, // 开发占用
  AUTH_FUNC_sample: 999,

  // 货主管理
  AUTH_PAGE_companyManage_NOSECRET: 1145,
  AUTH_PAGE_companyManage_SECRET: 1144,
  AUTH_FUNC_companyManage_DETAIL: 1092,
  AUTH_FUNC_companyManage_CREATE: 1093,
  AUTH_FUNC_companyManage_MODIFY: 1094,
  AUTH_FUNC_companyManage_LOG: 1095,
  AUTH_FUNC_companyManage_EFFECTIVE: 1096,

  // 系统参数配置
  AUTH_PAGE_SysParamsConfig_NOSECRET: 1196,
  AUTH_FUNC_SysParamsConfig_EDIT: 1190,

  // 节点规则
  AUTH_PAGE_nodeRule_NOSECRET: 1147,
  AUTH_FUNC_nodeRule_DETAIL: 1104,
  AUTH_FUNC_nodeRule_CREATE: 1106,
  AUTH_FUNC_nodeRule_MODIFY: 1105,

  // 库存交易流水
  AUTH_PAGE_transactionFlow_NOSECRET: 1153,

  // 货主组
  AUTH_PAGE_companyGroup_NOSECRET: 1143,
  AUTH_FUNC_companyGroup_VIEW: 1035,
  AUTH_FUNC_companyGroup_ADD: 1036,
  AUTH_FUNC_companyGroup_DELETE: 1037,

  // 配送管理
  AUTH_PAGE_TransOrderManage_SECRET: 1188,
  AUTH_PAGE_TransOrderManage_NOSECRET: 1140,
  AUTH_FUNC_TransOrderManage_VIEW: 1023,
  AUTH_FUNC_TransOrderManage_DETAIL: 1024,
  AUTH_FUNC_TransOrderManage_VIEW_ROUTE: 1025,
  AUTH_PAGE_TransOrderManage_SEND: 1026,
  AUTH_PAGE_TransOrderManage_CANCEL: 1027,
  AUTH_PAGE_TransOrderManage_EDIT: 1028,
  AUTH_PAGE_TransOrderManage_EXPORT: 2351,

  // 调拨单管理
  AUTH_PAGE_DISTRIBUTE_MANAGE: 381635,
  AUTH_FUNC_DISTRIBUTE_CANCEL: 421463,

  // 权限管理
  AUTH_PAGE_FUNCTIONMANAGE: 1156,
  AUTH_PAGE_ROLEMANAGE: 1157,

  // 出库单查询
  AUTH_PAGE_OutOrderSearch_SECRET: 1130,
  AUTH_PAGE_OutOrderSearch_NOSECRET: 1133,
  AUTH_FUNC_OutOrderSearch_EXPORT: 1009,
  AUTH_FUNC_OutOrderSearch_EXPORT_only_unsense: 400290,
  AUTH_FUNC_OutOrderSearch_DETAIL: 1062,
  AUTH_FUNC_OutOrderSearch_CANCEL: 1074,
  AUTH_FUNC_OutOrderSearch_ROUTE: 1076,
  AUTH_FUNC_OutOrderSearch_PULLWMSORDER: 1123,
  AUTH_FUNC_OutOrderSearch_INVALID: 400784,
  AUTH_FUNC_OutOrderSearch_MEDICINE_FLAG: 400982,
  AUTH_FUNC_OutOrderSearch_FORECAST: 422629,

  // 出库温湿度管理
  AUTH_PAGE_OutboundTemperatureAndHumidity_NOSECRET: 384770,
  AUTH_FUNC_OutboundTemperatureAndHumidity_DELETE: 384771,
  AUTH_FUNC_OutboundTemperatureAndHumidity_IMPORT: 385628,

  // 仓库管理
  AUTH_PAGE_WarehouseManage_SECRET: 1141,
  AUTH_PAGE_WarehouseManage_NOSECRET: 1142,
  AUTH_FUNC_WarehouseManage_CREATE: 1033,
  AUTH_FUNC_WarehouseManage_EDIT: 1034,
  AUTH_FUNC_WarehouseManage_NODE_VIEW: 1120,
  AUTH_FUNC_WarehouseManage_NODE_CREATE: 1121,
  AUTH_FUNC_WarehouseManage_NODE_EDIT: 1122,
  AUTH_FUNC_WarehouseManage_MAINTAIN_OPERATION: 1124,
  AUTH_FUNC_WarehouseManage_PAUSE_IMPORT: 436990,

  // 任务中心
  AUTH_PAGE_TaskCenter_NOSECRET: 1047,
  AUTH_FUNC_TaskCenter_COMPANY_CODE: 1048,

  // 规则中心-时效规则
  AUTH_PAGE_StrategyTimeRules_NOSECRET: 1150,
  AUTH_FUNC_StrategyTimeRules_VIEW: 1112,
  AUTH_FUNC_StrategyTimeRules_EDIT: 1113,
  AUTH_FUNC_StrategyTimeRules_CREATE: 1114,
  AUTH_FUNC_StrategyTimeRules_COPY: 431672,
  AUTH_FUNC_StrategyTimeRules_GROUP_IMPORT: 432598,

  // 规则中心-极效规则

  AUTH_MENU_EffectiveRules_NOSECRET: 2158,
  AUTH_PUN_EffectiveRules_VIEW: 2158,
  AUTH_PUN_EffectiveRules_EDIT: 2159,
  AUTH_PUN_EffectiveRules_CREATE: 2160,

  // 入库管理
  AUTH_MENU_inboundManage: 1001,
  // 入库管理
  AUTH_PAGE_inboundManage_SECRET: 1136,
  AUTH_PAGE_inboundManage_NOSECRET: 1137,
  AUTH_FUNC_inboundManage_EXPORT: 1068,
  AUTH_FUNC_inboundManage_CANCEL: 1069,
  AUTH_FUNC_inboundManage_ISSUE: 1070,
  AUTH_FUNC_inboundManage_DETAIL: 1073,
  AUTH_FUNC_inboundManage_EDIT: 1126,
  AUTH_FUNC_inboundManage_REORDER: 1127,
  AUTH_FUNC_inboundManage_IMPORT: 3042,
  AUTH_FUNC_inboundManage_INVALID: 400786,
  AUTH_FUNC_inboundManage_MEDICINE_FLAG: 400950,
  // 入库明细查询
  AUTH_PAGE_inboundDetailManage_NOSECRET: 1138,
  AUTH_FUNC_inboundDetailManage_EXPORT: 1071,
  // 入库序列号查询
  AUTH_PAGE_inboundNoManage_NOSECRET: 1139,
  AUTH_FUNC_inboundNoManage_EXPORT: 1072,

  // 分仓规则
  AUTH_PAGE_SubWarehouseRule_NOSECRET: 1148,
  AUTH_FUNC_SubWarehouseRule_VIEW: 1107,
  AUTH_FUNC_SubWarehouseRule_EDIT: 1108,

  // 管控规则
  AUTH_PAGE_AdminRuleManage_NOSECRET: 1149,
  AUTH_FUNC_AdminRuleManage_VIEW: 1109,
  AUTH_FUNC_AdminRuleManage_EDIT: 1110,
  AUTH_FUNC_AdminRuleManage_CREATE: 1111,
  AUTH_FUNC_AdminRuleManage_IMPORT: 3038,

  // 极效规则
  AUTH_PAGE_effectiveRules: 1056,

  // 模拟路由操作码规则
  AUTH_PAGE_RoutingOperationCodeRule_NOSECRET: 383900,
  AUTH_FUNC_RoutingOperationCodeRule_CREATE: 383897,
  AUTH_FUNC_RoutingOperationCodeRule_EDIT: 383898,
  AUTH_FUNC_RoutingOperationCodeRule_VIEW: 383899,

  // 打标规则
  AUTH_PAGE_MarkingRule_NOSECRET: 436329,
  AUTH_FUNC_MarkingRule_CREATE: 436331,
  AUTH_FUNC_MarkingRule_EDIT: 436333,
  AUTH_FUNC_MarkingRule_VIEW: 436332,

  // 出库单处理
  AUTH_PAGE_OrderManage_SECRET: 1131,
  AUTH_PAGE_OrderManage_NOSECRET: 1134,
  AUTH_FUNC_OrderManage_VIEW: 1063,
  AUTH_FUNC_OrderManage_EDIT: 1082,
  AUTH_FUNC_OrderManage_INTERCEPT: 1080,
  AUTH_FUNC_OrderManage_CANCEL: 1087,
  AUTH_FUNC_OrderManage_LOG: 1078,
  AUTH_FUNC_OrderManage_SPLITOCCUPY: 1079,
  AUTH_FUNC_OrderManage_SPLITOCCUPY_FORCE: 428634,
  AUTH_FUNC_OrderManage_RELEASEALL: 1081,
  AUTH_FUNC_OrderManage_TMS: 1083,
  AUTH_FUNC_OrderManage_WMS: 1084,
  AUTH_FUNC_OrderManage_CANCELTMS: 1086,
  AUTH_FUNC_OrderManage_CARRIER: 1085,
  AUTH_FUNC_OrderManage_EXPORT: 1010,
  AUTH_FUNC_OrderManage_EXPORT_only_unsense: 400291,
  AUTH_FUNC_OrderManage_IGNORE: 1125,
  AUTH_FUNC_OrderManage_MERGEORDER: 2239,
  AUTH_FUNC_OrderManage_DECRYPT: 2877,
  AUTH_FUNC_OrderManage_CANCELSTACK: 3000,
  AUTH_FUNC_OrderManage_IMPORT: 3042,
  AUTH_FUNC_OrderManage_PREPAY: 2995,
  AUTH_FUNC_OrderManage_medicineCheckGood: 381237,
  AUTH_FUNC_OrderManage_INVALID: 400785,
  AUTH_FUNC_OrderManage_CREATE: 400303,
  AUTH_FUNC_OrderManage_DSCCARRIEREDIT: 402536,
  AUTH_FUNC_OrderManage_MEDICINEUNUSUALRECHECK: 407907,
  AUTH_FUNC_OrderManage_CHECKAPPLEADDRESS: 433746,
  AUTH_FUNC_OrderManage_SKUEDIT: 408692,
  AUTH_FUNC_OrderManage_TAB1000: 429001,
  AUTH_FUNC_OrderManage_TAB1100: 429002,
  AUTH_FUNC_OrderManage_TAB1200: 429003,
  AUTH_FUNC_OrderManage_TAB1500: 429004,
  AUTH_FUNC_OrderManage_TAB2000: 429005,
  AUTH_FUNC_OrderManage_TAB3000: 429006,
  AUTH_FUNC_OrderManage_TAB30001: 429007,
  AUTH_FUNC_OrderManage_TAB30002: 429008,
  AUTH_FUNC_OrderManage_TAB4000: 429009,

  // 商品管理模块
  AUTH_PAGE_GoodsManagement_NOSECRET: 1146,
  AUTH_FUNC_GoodsManagement_VIEW: 1097,
  AUTH_FUNC_GoodsManagement_CREATE: 1098,
  AUTH_FUNC_GoodsManagement_EDIT: 1099,
  AUTH_FUNC_GoodsManagement_PUSHORDER: 1100,
  AUTH_FUNC_GoodsManagement_EXPORT: 1115,

  // basicCodeConfig
  AUTH_PAGE_BasicCodeConfig_NOSECRET: 1154,
  AUTH_FUNC_BasicCodeConfig_EXPORT: 1118,
  AUTH_FUNC_BasicCodeConfig_EDIT: 1119,

  // 货主仓库库存管理
  AUTH_PAGE_CompanyWarehouseStockManage_NOSECRET: 1151,
  AUTH_FUNC_CompanyWarehouseStockManage_IMPORT: 2470,
  // 货主仓库管理
  AUTH_PAGE_CompanyStockManage_NOSECRET: 1152,

  // BOM管理
  AUTH_PAGE_BomManage_NOSECRET: 2245,
  AUTH_FUNC_BomManage_CREATE: 2244,
  AUTH_FUNC_BomManage_PUSH: 2424,
  AUTH_FUNC_BomManage_EDIT: 413685,
  AUTH_FUNC_BomManage_EFFECT: 413684,

  // 供应商管理
  AUTH_PAGE_SupplierManage_NOSECRET: 2379,
  AUTH_PAGE_SupplierManage: 2372,
  AUTH_FUNC_SupplierManage_IMPORT: 2373,
  AUTH_FUNC_SupplierManage_PUSH: 2374,
  AUTH_FUNC_SupplierManage_EXPORT: 2375,
  AUTH_FUNC_SupplierManage_DETAIL: 2376,
  AUTH_FUNC_SupplierManage_LOG: 2377,
  AUTH_FUNC_SupplierManage_EDIT: 2378,

  // 库存中心内部对账差异
  AUTH_PAGE_StockInnerDiffBill: 1202,

  // 库存快照
  AUTH_PAGE_StockPicture: 1199,

  // 缺货信息管理
  AUTH_PAGE_LackSku_SECRET: 1132,
  AUTH_PAGE_LackSku_NOSECRET: 1135,
  AUTH_FUNC_LackSku_EXPORT: 1129,

  // 库存对账
  AUTH_PAGE_InventoryReconciliation: 1197,
  AUTH_FUNC_InventoryReconciliation_DETAIL: 1164,

  // 库存版本明细
  AUTH_PAGE_InventoryVersionDetail: 1198,
  AUTH_FUNC_InventoryVersionDetail_STATEMENT: 1200,
  AUTH_FUNC_InventoryVersionDetail_EXPORT: 1201,

  // 公告管理
  AUTH_MENU_AnnounceManage: 2347,
  AUTH_PAGE_AnnounceManage: 2348,
  AUTH_FUNC_AnnounceManageUpdate: 2349,
  AUTH_FUNC_AnnounceManageView: 2350,

  // 丰向标入口
  AUTH_PAGE_FENGXIANGBIAO: 388279,

  // 拆单规则
  AUTH_PAGE_SplitOrderRule_NOSECRET: 2369,
  AUTH_FUNC_SplitOrderRule_EDIT: 2370,

  // 拆单管理
  AUTH_PAGE_OrderSplit: 1195,
  // 合单管理
  AUTH_PAGE_OrderMerge_NOSECRET: 2238,
  AUTH_FUNC_OrderMerge_EXPORT: 377691,

  // 库存推送规则
  AUTH_PAGE_InvBatchRule_NOSECRET: 2486,
  AUTH_FUNC_InvBatchRule_DETAIL: 2485,
  AUTH_FUNC_InvBatchRule_EDIT: 2484,
  AUTH_FUNC_InvBatchRule_CREATE: 2483,
  // 接口模板配置
  AUTH_PAGE_InterfaceModuleConfig_NOSECRET: 2505,
  AUTH_FUNC_InterfaceModuleConfig_EDIT: 2502,
  AUTH_FUNC_InterfaceModuleConfig_VIEW: 2504,
  AUTH_FUNC_InterfaceModuleConfig_CREATE: 2501,
  AUTH_FUNC_InterfaceModuleConfig_EFFECT: 2503,

  // 异常报文监控
  AUTH_PAGE_ExceptMessage_NOSECRET: 2694,
  AUTH_FUNC_ExceptMessage_VIEW: 2695,
  AUTH_FUNC_ExceptMessage_EDIT: 2696,
  AUTH_FUNC_ExceptMessage_PUSH: 2697,
  AUTH_FUNC_ExceptMessage_LOG: 2698,

  // 商品审核
  AUTH_PAGE_SkuAudit_NOSECRET: 2961,
  AUTH_FUNC_SkuAudit_AUDIT: 2962,

  // WMS地址配置
  AUTH_PAGE_WmsAddressConfig_NOSECRET: 2973,

  // WMS对接配置
  AUTH_PAGE_WmsDockingConfig_NOSECRET: 2974,

  // 库存中心盘点审核
  AUTH_PAGE_InventoryAudit_NOSECRET: 2968,
  AUTH_FUNC_InventoryAudit_EXPORT: 2971,
  AUTH_FUNC_InventoryAudit_UPLOAD: 2969,
  AUTH_FUNC_InventoryAudit_CANCELUPLOAD: 2970,

  // 规则中心：拦截调用规则
  AUTH_PAGE_InterceptRule_NOSECRET: 2996,
  AUTH_FUNC_InterceptRule_VIEW: 2999,
  AUTH_FUNC_InterceptRule_EDIT: 2998,
  AUTH_FUNC_InterceptRule_CREATE: 2997,

  // 导入模板配置
  AUTH_PAGE_ImportModuleConfig_NOSECRET: 3039,
  AUTH_FUNC_ImportModuleConfig_VIEW: 3040,
  AUTH_FUNC_ImportModuleConfig_EDIT: 3041,

  // 预付款规则
  AUTH_PAGE_PrePayRule_NOSECRET: 2991,
  AUTH_FUNC_PrePayRule_DETAIL: 2992,
  AUTH_FUNC_PrePayRule_EDIT: 2993,
  AUTH_FUNC_PrePayRule_CREATE: 2994,

  // 国际进口件
  AUTH_PAGE_ImportItem_NOSECRET: 3043,
  AUTH_FUNC_ImportItem_EXPORT: 3044,
  AUTH_FUNC_ImportItem_LOG: 3045,

  // 集运单
  AUTH_PAGE_CollectOrderPage_NOSECRET: 153212,
  AUTH_FUNC_CollectOrderPage_DETAIL: 153213,
  // 关务品名维护
  AUTH_PAGE_CustomsNameMaintenance_NOSECRET: 152859,
  AUTH_FUNC_CustomsNameMaintenance_EDIT: 152860,
  // 无主件识别处理
  AUTH_PAGE_UnknownManage_NOSECRET: 153472,
  AUTH_FUNC_UnknownManage_IMPORT: 153470,
  AUTH_FUNC_UnknownManage_EXPORT: 153471,

  // 自提网点信息维护
  AUTH_PAGE_SelfServiceStation_NOSECRET: 152848,
  AUTH_FUNC_SelfServiceStation_CREATE: 152849,
  AUTH_FUNC_SelfServiceStation_EDIT: 152850,
  AUTH_FUNC_SelfServiceStation_IMPORT: 152851,
  AUTH_FUNC_SelfServiceStation_EXPORT: 152852,

  // 集运管理目录
  AUTH_PAGE_CollectTransCenter_NOSECRET: 152847,

  // 通用的日志权限码
  AUTH_FUNC_LOG: 2377,

  AUTH_FUNC_WmsAddressConfig_ENCRYPT: 161148,

  // 郊区件
  AUTH_PAGE_SuburbanPartsManagement_NOSECRET: 376905,
  AUTH_FUNC_SuburbanPartsManagement_EXPORT: 376909,
  AUTH_FUNC_SuburbanPartsManagement_CREATE: 376906,
  AUTH_FUNC_SuburbanPartsManagement_EDIT: 376907,
  AUTH_FUNC_SuburbanPartsManagement_DELETE: 376910,
  // 调拨关联明细录入
  AUTH_PAGE_AllocationDetail_NOSECRET: 373357,
  AUTH_FUNC_AllocationDetail_IMPORT: 373360,
  AUTH_FUNC_AllocationDetail_EDIT: 373358,
  AUTH_FUNC_AllocationDetail_DELETE: 373359,

  // 月台预约单查询
  AUTH_PAGE_PlatformManage: 375324,
  AUTH_FUNC_PlatformManage_APPROVAL: 375325,
  AUTH_FUNC_PlatformManage_SIGNIN: 375326,
  AUTH_FUNC_PlatformManage_LOADINGBEGIN: 375327,
  AUTH_FUNC_PlatformManage_FINISH: 375328,
  AUTH_FUNC_PlatformManage_CANCEL: 375329,
  AUTH_FUNC_PlatformManage_EDIT: 375450,
  AUTH_FUNC_PlatformManage_EXPORT: 377040,

  // 仓库绑定月台
  AUTH_FUNC_BindPlatform_LIST: 377031,
  AUTH_FUNC_BindPlatform_CREATE: 377033,
  AUTH_FUNC_BindPlatform_EDIT: 377034,
  AUTH_FUNC_BindPlatform_DETAIL: 377032,

  // 仓库绑定月台预约时间
  AUTH_FUNC_PlatformPeriod_LIST: 377035,
  AUTH_FUNC_PlatformPeriod_CREATE: 377037,
  AUTH_FUNC_PlatformPeriod_EDIT: 377038,
  AUTH_FUNC_PlatformPeriod_DETAIL: 377036,
  AUTH_FUNC_PlatformPeriod_DEL: 377039,

  // 订单对应出库时间
  AUTH_PAGE_OrderCorrespondDeliveryTime_NOSECRET: 375053,
  AUTH_FUNC_OrderCorrespondDeliveryTime_IMPORT: 375054,
  AUTH_FUNC_OrderCorrespondDeliveryTime_EDIT: 375055,
  AUTH_FUNC_OrderCorrespondDeliveryTime_CREATE: 375056,
  AUTH_FUNC_OrderCorrespondDeliveryTime_DELETE: 375057,
  AUTH_FUNC_OrderCorrespondDeliveryTime_EXPORT: 375058,

  // 特殊时效发送配置
  AUTH_PAGE_SpecialEffectiveSendConf_NOSECRET: 375062,
  AUTH_FUNC_SpecialEffectiveSendConf_IMPORT: 375063,
  AUTH_FUNC_SpecialEffectiveSendConf_EDIT: 375064,
  AUTH_FUNC_SpecialEffectiveSendConf_CREATE: 375065,
  AUTH_FUNC_SpecialEffectiveSendConf_DELETE: 375066,
  AUTH_FUNC_SpecialEffectiveSendConf_EXPORT: 375067,
  // 通缉单查询
  AUTH_PAGE_OrderWanted_NOSECRET: 386958,
  AUTH_FUNC_OrderWanted_WANTED: 386959,
  AUTH_FUNC_OrderWanted_EXPORT: 386960,

  // 进销存管理
  AUTH_PAGE_PurchaseSaleManage_NOSECRET: 391439,
  AUTH_FUNC_PurchaseSaleManage_EXPORT: 391442,

  // 加工单管理
  AUTH_PAGE_ProcessManage_NOSECRET: 403629,
  AUTH_FUNC_ProcessManage_RECHECK: 403634,
  AUTH_FUNC_ProcessManage_PUSH: 403635,
  AUTH_FUNC_ProcessManage_CANCEL: 403636,
  AUTH_FUNC_ProcessManage_EXPORT: 403637,

  // 加工单详情管理
  AUTH_PAGE_ProcessDetailManage_MATERIAL: 403630,
  AUTH_PAGE_ProcessDetailManage_SKU: 403631,
  AUTH_PAGE_ProcessDetailManage_MATERIALCOMP: 403632,
  AUTH_PAGE_ProcessDetailManage_SKUCOMP: 403633,

  // 订单日志详情查看
  AUTH_FUNC_OrderLogDetail_VIEW: 403570,

  // 门店管理
  AUTH_PAGE_StoreManage_NOSECRET: 408105,
  AUTH_FUNC_StoreManage_DETAIL: 408106,
  AUTH_FUNC_StoreManage_SUG: 408107,
  AUTH_FUNC_StoreManage_EDIT: 408108,
  AUTH_PAGE_StoreManage_PUSH: 408109,

  // PO采购订单管理
  AUTH_PAGE_PurchaseManagement: 414523,
  AUTH_PAGE_PurchaseManagementDetail: 414524,
  AUTH_FUNC_PurchaseManagement_EXPORT: 414717,
  AUTH_FUNC_PurchaseManagement_CLOSE: 423283,

  // 动态称
  AUTH_PAGE_DynamicScale_NOSECRET: 423077,
  AUTH_FUNC_DynamicScale_CREATE: 423078,
  AUTH_FUNC_DynamicScale_DELETE: 423079,

  // 规则绑定详情
  AUTH_PAGE_bindRulesDetail: 423668,
  AUTH_FUNC_bindRulesDetail_EXPORT: 423669,

  // 销售原单管理
  AUTH_PAGE_OriginalOrderManage_NOSECRET: 430384,
  AUTH_FUNC_OriginalOrderManage_DETAIL: 430385,
  AUTH_FUNC_OriginalOrderManage_CONVERT: 430386,

  // 销售原单详情
  AUTH_PAGE_OriginalOrderDetail_NOSECRET: 430385,
  AUTH_FUNC_OriginalOrderDetail_SECRET: 430385, // 目前没有非脱敏的权限，和脱敏用一个

  // 销售订单管理
  AUTH_PAGE_SaleOrderManage_NOSECRET: 430384,
  AUTH_FUNC_SaleOrderManage_DETAIL: 430385,
  AUTH_FUNC_SaleOrderManage_APPROVE: 432264,
  AUTH_FUNC_SaleOrderManage_DISAPPROVE: 432263,

  // 销售订单详情
  AUTH_PAGE_SaleOrderDetail_NOSECRET: 430385,
  AUTH_PAGE_SaleOrderDetail_SECRET: 430385, // 目前没有非脱敏的权限，和脱敏用一个

  // 店铺管理
  AUTH_PAGE_ShopManage_NOSECRET: 431662,
  AUTH_FUNC_ShopManage_DETAIL: 431663,
  AUTH_FUNC_ShopManage_CREATE: 431664,
  AUTH_FUNC_ShopManage_EDIT: 431665,
  AUTH_FUNC_ShopManage_FM_CREATE: 431666,
  AUTH_FUNC_ShopManage_FM_AUTH: 431667,

  // 时效规则流向配置
  AUTH_PAGE_TimeRuleFlow_NOSECRET: 431668,
  AUTH_FUNC_TimeRuleFlow_IMPORT: 431669,
  AUTH_FUNC_TimeRuleFlow_EXPORT: 431670,

  // KA客户打标
  AUTH_PAGE_KACustomerMarking_NOSECRET: 436830,
  AUTH_FUNC_KACustomerMarking_CREATE: 436832,
  AUTH_FUNC_KACustomerMarking_EDIT: 436833,
  AUTH_FUNC_KACustomerMarking_ENABLE: 436834,
};

export default authMap;
