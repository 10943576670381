import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import React from 'react';
import { RawIntlProvider } from 'react-intl';
import { LocaleProvider } from 'scc-oms-components';
import socEn from 'scc-oms-components/locale/en-US';
import socZh from 'scc-oms-components/locale/zh-CN';

import { getCurrentLocale, getGlobalIntl } from './i18n';

interface ILanguageProviderProps {
  children: any;
}

export function LanguageProvider(props: ILanguageProviderProps) {
  const locale = getCurrentLocale();
  const globalIntl = getGlobalIntl();

  // 这里的事件监听可以实时获取到语言环境的变化，但是项目中不太需要这样做，先注释掉了
  // const [locale, setLocale] = useState(getCurrentLocale());
  // const [globalIntl, setGlobalIntl] = useState(getGlobalIntl());
  // useEffect(() => {
  //   const handleI18nChange = () => {
  //     setLocale(getCurrentLocale());
  //     setGlobalIntl(getGlobalIntl());
  //   };
  //   window.addEventListener('i18nChange', handleI18nChange);
  //   return () => {
  //     window.removeEventListener('i18nChange', handleI18nChange);
  //   };
  // }, []);

  let antdLanguage = zhCN;
  let socLanguage = socZh;
  dayjs.locale('zh-cn');
  if (locale === 'en-US') {
    antdLanguage = enUS;
    dayjs.locale('en');
    socLanguage = socEn;
  }

  return (
    <RawIntlProvider value={globalIntl}>
      <ConfigProvider locale={antdLanguage}>
        <LocaleProvider locale={socLanguage}>{React.Children.only(props.children)}</LocaleProvider>
      </ConfigProvider>
    </RawIntlProvider>
  );
}

export default LanguageProvider;
