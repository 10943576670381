/*
 * @Author: 董方旭
 * @Date: 2021-02-01 11:14:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-12 17:35:43
 * @Description: tab标签
 */
import { Tabs } from 'antd';
import longan from 'longan-sdk';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { dropByCacheKey } from 'react-router-cache-route';
import { useHistory } from 'react-router-dom';

import { actions } from 'store/globalSlice';
import {
  selectActiveCacheKey,
  selectCachingKeys,
  selectDesensitizeConf,
  selectDesensitizeFlagMap,
} from 'store/selectors';
import { menuMessages } from 'utils/messages';
import { isInFrame } from 'utils/utils';

import { MultipleTabsWrapper } from './Wrappers';

const { TabPane } = Tabs;

function MultipleTabs() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const cachingKeys = useSelector(selectCachingKeys);
  const activeCacheKey = useSelector(selectActiveCacheKey);
  const desensitizeFlagMap = useSelector(selectDesensitizeFlagMap);
  const desensitizeConf = useSelector(selectDesensitizeConf);

  const handleTabChange = (activeKey: string) => {
    const newPath = activeKey;
    dropByCacheKey(activeKey);
    history.push(newPath);
    dispatch(actions.updateActiveCacheKey(newPath));
    const mesKey = newPath.split('/').pop();
    const text = intl.formatMessage(menuMessages[mesKey]);
    longan.dispatch({
      event_desc: text,
      event_name: `page-${text}`,
      event_type: 'selfDefine',
    });
    // 触发resize事件
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  };

  const handleTabEdit = (targetKey: string, action: string) => {
    if (action === 'remove') {
      if (cachingKeys.length === 1) {
        // 最后一个不允许删除
        return;
      }
      // dropByCacheKey(targetKey);
      dispatch(actions.updateCachingKeys(cachingKeys.filter((key: string) => key !== targetKey)));

      // 当页面关闭时, 重置页面对应的脱敏状态
      const path = targetKey.split('/:')[0];
      const realPath = targetKey.split('?')[0];
      if (
        Object.keys(desensitizeFlagMap).includes(realPath) &&
        desensitizeConf?.[path]?.isEdit &&
        !desensitizeConf?.[path]?.isForcedDesensitized
      ) {
        dispatch(actions.updateDesensitizePageFlagMap({ [realPath]: '0' }));
      }

      if (activeCacheKey === targetKey) {
        const index = cachingKeys.indexOf(targetKey);
        const nextKey = index === 0 ? cachingKeys[1] : cachingKeys[index - 1];
        history.push(nextKey);
        dispatch(actions.updateActiveCacheKey(nextKey));
        dropByCacheKey(targetKey);
      } else {
        dropByCacheKey(targetKey);
      }
    }
  };

  const formatPanelText = (pane: string) => {
    const paramsArray = pane.split('/:');
    let detailId = '';
    if (paramsArray.length === 2) {
      const tempArr = paramsArray[1].split('?');
      detailId = tempArr.length ? tempArr[0] : '';
    }
    const paths = paramsArray[0].split('/');
    if (paths.length) {
      const pathKey = paths[paths.length - 1];
      let text = intl.formatMessage(menuMessages[pathKey]);
      // 特殊处理 dsc嵌入
      if (isInFrame()) {
        text = paths?.[2]?.includes('orderManage') ? intl.formatMessage(menuMessages.orderManage) : text;
        text = paths?.[2]?.includes('outOrderSearch') ? intl.formatMessage(menuMessages.outOrderSearch) : text;
      }
      if (detailId) {
        return `${text} - ${detailId}`;
      }
      return text;
    }
    return '';
  };

  return (
    <MultipleTabsWrapper>
      <div className="tabs">
        <Tabs
          hideAdd
          activeKey={activeCacheKey}
          type="editable-card"
          onEdit={(targetKey: string) => handleTabEdit(targetKey, 'remove')}
          onChange={handleTabChange}
        >
          {cachingKeys.map((pane: string) => (
            <TabPane tab={formatPanelText(pane) || '-'} key={pane} />
          ))}
        </Tabs>
      </div>
    </MultipleTabsWrapper>
  );
}

export default MultipleTabs;
