import { i18nConf } from 'configs/base.conf';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';
import { getCookie, setCookie } from 'utils/utils';
import enMessages from '../translations/en-US.json';
import zhMessages from '../translations/zh-CN.json';
import { getRequest } from '../utils/request';

let globalIntl: IntlShape = null;
// 获取cookie中的语言设置
const localeInCookie = () => {
  const val = getCookie(i18nConf.cookieName);
  if (!val || !Object.values(i18nConf.cookieFieldMap).includes(val)) {
    setCookie(i18nConf.cookieName, i18nConf.cookieFieldMap[i18nConf.defaultLocale]);
    return i18nConf.cookieFieldMap[i18nConf.defaultLocale];
  }
  return val;
};
// 用cookie中的转换成项目用的
const currentLocale = i18nConf.reversedCookieFieldMap[localeInCookie()];

const cache = createIntlCache(); // 只需要创建一次缓存

// 创建全局 intl 对象的辅助函数
function createGlobalIntl(messages: TDictionary<string>) {
  return createIntl(
    {
      locale: currentLocale,
      messages,
      defaultLocale: i18nConf.defaultLocale,
    },
    cache,
  );
}
// 创建默认的 fallback intl 对象
function createFallbackIntl() {
  return createIntl(
    {
      locale: currentLocale,
      messages: currentLocale === 'zh-CN' ? zhMessages : enMessages,
      defaultLocale: i18nConf.defaultLocale,
    },
    cache,
  );
}

export async function initIntl() {
  try {
    // 请求远程接口获取国际化数据
    const res = await getRequest<TDictionary<unknown>, TDictionary<string>>('/omsbase/i8n/getmap', {
      lang_type: currentLocale.split('-')[0],
      svc_name: 'janus',
      sys_code: 'SCC-OMS-CORE-NEW',
    });

    // 判断请求结果是否有效
    if (res.errno === 0 && res.data && typeof res.data === 'object') {
      globalIntl = createGlobalIntl(res.data);
    } else {
      globalIntl = createFallbackIntl();
    }
  } catch (e) {
    console.error('Error initializing intl:', e);
    globalIntl = createFallbackIntl();
  }

  // 返回全局 intl 对象
  return globalIntl;
}

/** 获取全局的intl对象 */
export function getGlobalIntl() {
  return globalIntl;
}

/** 获取当前语言环境 */
export function getCurrentLocale() {
  return currentLocale;
}

/**
 * 项目切换语言环境
 * @description 用在语言切换按钮上
 */
export function changeI18nEnv() {
  // 获取当前语言环境对应的cookie值
  const cookieLang = getCookie(i18nConf.cookieName);
  // 切换成新的语言
  const newCookieLang =
    cookieLang === i18nConf.cookieFieldMap[i18nConf.defaultLocale]
      ? i18nConf.cookieFieldMap['en-US']
      : i18nConf.cookieFieldMap[i18nConf.defaultLocale];

  setCookie(i18nConf.cookieName, newCookieLang);

  // 这里dispatch一个自定义事件，目的让其他页面能够通过监听感知到语言环境切换
  // 但切换环境之后会进行页面刷新，所以这个操作看没用，暂时注释掉了
  // const localeChangeEvent = new CustomEvent('i18nChange');
  // window.dispatchEvent(localeChangeEvent);

  // 直接刷新页面
  window.location.reload();
}
