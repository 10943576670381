/*
 * @Author: your name
 * @Date: 2021-04-23 18:02:15
 * @LastEditTime: 2021-04-23 18:08:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /janus/src/store/utils.ts
 */
import { IMenuDataItem } from 'components/Router/types';
import menuConf from 'configs/router.conf';

import { IDesensitizeConf } from './types';

/* 权限下的脱敏 start */
interface IFlattenMenuConfItem {
  path: string;
  name: string;
  authOnlySecret: number | undefined;
  authOnlyNoSecret: number | undefined;
  isForcedDesensitized?: boolean;
}
// 1 把路由文件的树形结构 打平成 扁平数组
function flatten(data: IMenuDataItem[], parentPath = '/'): IFlattenMenuConfItem[] {
  return data.reduce((arr, cur) => {
    if (cur.children) {
      return arr.concat(
        {
          path: parentPath + cur.path,
          name: cur.name,
          authOnlyNoSecret: cur.authOnlyNoSecret,
          authOnlySecret: cur.authOnlySecret,
          isForcedDesensitized: cur.isForcedDesensitized,
        },
        flatten(cur.children, `${parentPath + cur.path}/`),
      );
    }
    return arr.concat({
      path: parentPath + cur.path,
      name: cur.name,
      authOnlyNoSecret: cur.authOnlyNoSecret,
      authOnlySecret: cur.authOnlySecret,
      isForcedDesensitized: cur.isForcedDesensitized,
    });
  }, []);
}

export function generateDesensitizeConf(authList: number[]) {
  // 2 把没有权限的菜单过滤掉
  const filterHasAuthMenuConf = flatten(menuConf).filter(
    (item: IFlattenMenuConfItem) => item.authOnlyNoSecret || item.authOnlySecret,
  );
  // 3 拿根据用户的authList 和 filterHasAuthArr 来 生成一份该用户的脱敏配置
  return filterHasAuthMenuConf.reduce((acc: { [key: string]: IDesensitizeConf }, cur: IFlattenMenuConfItem) => {
    const authOnlyNoSecret = cur.authOnlyNoSecret && authList.includes(cur.authOnlyNoSecret);
    const authOnlySecret = cur.authOnlySecret && authList.includes(cur.authOnlySecret);
    let status: '0' | '1' = '0';
    // 有非脱敏权限 并且 没有脱敏权限
    if (authOnlyNoSecret && !authOnlySecret) {
      status = '1';
    }
    return {
      ...acc,
      [cur.path]: {
        url: cur.path,
        name: cur.name,
        authOnlyNoSecret: authOnlyNoSecret ? cur.authOnlyNoSecret : undefined,
        authOnlySecret: authOnlySecret ? cur.authOnlySecret : undefined,
        isEdit: !!(authOnlyNoSecret && authOnlySecret),
        isForcedDesensitized: cur.isForcedDesensitized,
        status,
      },
    };
  }, {});
}
/* 权限下的脱敏 end */

export default {};
