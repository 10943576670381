/*
 * @Author: 董方旭
 * @Date: 2021-02-23 14:31:53
 * @LastEditors: liqingqing
 * @LastEditTime: 2021-10-11 14:17:32
 * @Description: 主要有PAGE和FUNC两种权限, 在authmap中取到undefined，则默认显示权限
 */
import onlineAuth from './auth.online.conf';
import testAuth from './auth.test.conf';
import uatAuth from './auth.uat.conf';

// uat、test环境权限没有这么严格，所以key不会和online一一对应，这里用Partial处理一下
type TAuthMap = Partial<typeof onlineAuth>;

const authMap = {
  online: onlineAuth,
  uat: uatAuth as TAuthMap,
  test: testAuth as TAuthMap,
};

export default authMap[(process.env.PROJECT_ENV as 'online' | 'uat' | 'test') || 'uat'];
