import React from 'react';
import { useHistory, type LinkProps } from 'react-router-dom';

import TooltipSpan from './TooltipSpan';

type TCustomDivLinkProps = {
  beforeClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & Pick<LinkProps, 'to' | 'children' | 'replace'>;

const CustomLink = ({ beforeClick, to, children, replace }: TCustomDivLinkProps) => {
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (beforeClick) {
      event.preventDefault(); // 阻止默认行为
      beforeClick(event); // 执行自定义逻辑
    }
    if (replace) {
      history.replace(to as string);
    } else {
      history.push(to as string); // 导航到新路径
    }
  };

  return (
    <button
      // 将button的默认样式移除
      style={{
        border: 'none',
        backgroundColor: 'transparent',
        width: '100%',
        height: 'inherit',
        overflow: 'hidden',
        padding: 0,
        margin: 0,
        cursor: 'pointer',
        font: 'inherit',
        color: 'inherit',
        outline: 'none',
      }}
      type="button"
      onClick={handleClick}
    >
      <TooltipSpan innerSpanStyle={{ paddingRight: '8px' }} offsetRX={16}>
        {children}
      </TooltipSpan>
    </button>
  );
};

/**
 * 用Btn模拟一下react-router-dom的Link组件，原因是Link使用的a标签有固定的样式，会导致导航菜单样式不对。
 * 同时增加了一些自定义的逻辑，后续有需要可以再加
 * @description 1、添加了beforeClick属性，主要用于切换路由之前进行一些前置操作，比如改变页面标题（神策搜集数据需要）
 */
export default CustomLink;
